<template>
  <div class="common-dashboard-page app-container">
    <div class="cards-container" v-loading="loading">
      <div
        v-for="item in list"
        :key="item.id"
        class="item"
        :style="{
          width: cardWidthReander(item),
        }"
      >
        <SingleCard :dataset="item" />
      </div>
    </div>
  </div>
</template>

<script>
import SingleCard from "../../vlink/dashboardEdit/components/singleCard.vue";
import { getTemplateDetailById } from "@/api/ruge/bms/indicatorManagement";

export default {
  name: "common-dashboard-page",
  components: {
    SingleCard,
  },
  data() {
    return {
      loading: false,
      list: [],
    };
  },
  created() {
    this.initDatas();
  },
  watch: {
    $route: {
      handler() {
        this.initDatas()
      },
    },
  },
  methods: {
    initDatas() {
      const { templateId } = this.$route.query;
      if (!templateId) return;
      this.loading = true;
      getTemplateDetailById({ templateId })
        .then((res) => {
          this.list = res.cardList;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cardWidthReander(item) {
      const marginMap = {
        1: 20,
        2: 10,
        3: 0,
      };
      const oneStep = 33.3;
      let finalWidth = 33.3;
      const cardSizeParse = JSON.parse(item.cardSize);
      finalWidth = cardSizeParse.mode * oneStep;
      return `calc(${finalWidth}% - ${marginMap[cardSizeParse.mode]}px)`;
    },
  },
};
</script>

<style lang="less" scoped>
.common-dashboard-page {
  padding: 20px 0px;
  background-color: #ffffff00;
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;
  }
  .item {
    width: 33.3%;
    border-radius: 5px;
    color: #000;
  }
}
</style>